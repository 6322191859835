<template>
  <div class="row">
    <div
      class="modal fade"
      id="modal-form-solicitudes-nacionales-empresas"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignar Empresa</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row pl-5">
              <div
                v-for="item in items"
                :key="item.id"
                class="card col-md-5 p-2 m-2"
              >
                <p><strong>Origen: </strong>{{ item.direccion_origen }}</p>
                <p><strong>Destino: </strong>{{ item.direccion_destino }}</p>
                <p>
                  <strong>Tipo Vehiculo: </strong
                  >{{ item.tipo_vehiculo.nombre }}
                </p>
              </div>
            </div>
            <div class="row pl-5 p-1">
              <div class="form-group col-md-10">
                <label>Transportadora</label>
                <v-select
                  :class="[
                    $v.form.transportadora.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="form.transportadora"
                  placeholder="Transportadora"
                  label="razon_social"
                  :options="listasForms.transportadoras"
                  class="form-control form-control-md p-0"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              @click="save()"
              v-if="!$v.form.$invalid"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "SolicitudesNacionalesEmpresa",
  components: {
    vSelect,
  },
  data() {
    return {
      moment: moment,
      cargando: false,
      data_solicitud: {
        conductor: {},
        vehiculo: {},
      },
      listasForms: {
        transportadoras: [],
      },
      items: {},
      form: {
        transportadora: null,
      },
    };
  },
  validations() {
    return {
      form: {
        transportadora: {
          required,
        },
      },
    };
  },

  methods: {
    async llenar_modal(item) {
      await this.getTransportadoras();
      this.items = item;
    },

    getTransportadoras() {
      axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [5],
          },
        })
        .then((response) => {
          this.listasForms.transportadoras = response.data;
        });
    },

    async save() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        this.form.items = this.items;
        await axios({
          method: "POST",
          url: "/api/tep/solicitudesNacionales",
          data: this.form,
        })
          .then((response) => {
            this.$parent.cargando = false;
            this.$refs.closeModal.click();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "Se asigno la empresa transportadora correctamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$parent.cargando = false;
            this.$swal({
              icon: "error",
              title: `Ha ocurrido un error: ${e.response.data.message}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
};
</script>
